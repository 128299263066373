import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ENDPOINT } from '../enum/endpoint.enum';
import { BASE_API_URL } from '../utils/injectors';
import { InputModel } from '../models/InputModel';
import { VisuAndKpis } from '../models/VisuAndKpis';
import { ApiTranslatorService } from './apitranslator.service';

@Injectable({
  providedIn: 'root'
})
export class InputService {
  private readonly urlInputs: string;

  constructor(
    private readonly http: HttpClient,
    private readonly apiTranslatorService: ApiTranslatorService,
    @Inject(BASE_API_URL) private readonly apiUrl: string
  ) {
    this.urlInputs = `${apiUrl}/${ENDPOINT.INPUTS}/`;
  }

  /**
   * HTTP request to Galia API to get an optimSpace input.
   * We add an id to input visu data to know when visu data changes.
   * @param inputId a number that represents the id of the input
   * @returns an observable that emits the selected input
   */
  getInput(inputId: number): Observable<InputModel> {
    return this.http
      .request('GET', this.urlInputs + inputId)
      .pipe(map((r) => this.apiTranslatorService.modelToClass(InputModel, r)));
  }

  getInputVisuAndKpis(inputId: number): Observable<VisuAndKpis> {
    return this.http.get<VisuAndKpis>(`${this.urlInputs}${inputId}/visu-and-kpi`).pipe(
      map((visuAndKpis: VisuAndKpis) => {
        visuAndKpis.visuData._id = `input-${inputId}`;
        return visuAndKpis;
      })
    );
  }

  /**
   * HTTP request to delete an input
   * @param optimSpaceId a number that represents the input ID
   * @returns
   */
  deleteInput(inputId: number): Observable<any> {
    return this.http.delete(this.urlInputs + inputId);
  }
}
