import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { mergeAll } from 'rxjs/operators';
import { RootState } from '../store';
import { WebsocketService } from '../../websocket.service';

// Example to understand how we combine streams for the different events (application)
// we listen to.
// Copy-Paste to vizualise: https://rxviz.com/

/* 
const { interval, empty } = Rx;
const { map, pipe, mergeAll, take } = RxOperators;
const arrOfObs$ = [
  empty(),
  interval(1000).pipe(take(1), map(() => "A")),
  interval(1000).pipe(take(2), map(() => "B")),
  interval(1000).pipe(take(1), map(() => "C")),
  interval(1000).pipe(take(3), map(() => "D"))
]
const obsEmitter$$ = interval(2000).pipe(
  take(arrOfObs$.length),
  map(i => arrOfObs$[i])
)
const mergedStream$ = obsEmitter$$.pipe(mergeAll())
mergedStream$
*/

@Injectable({
  providedIn: 'root'
})
export class OptimizationService {
  private readonly optimizationListensSubject$$ = new Subject<Observable<any>>();
  private readonly recomputeListensSubject$$ = new Subject<Observable<any>>();

  optimization$: Observable<any> = this.optimizationListensSubject$$
    .asObservable()
    .pipe(mergeAll());
  recompute$: Observable<any> = this.recomputeListensSubject$$
    .asObservable()
    .pipe(mergeAll());

  constructor(
    private readonly socket: WebsocketService,
    private readonly store: Store<RootState>
  ) {}

  optimizationListen(appPath: string) {
    this.optimizationListensSubject$$.next(this.socket.listen(appPath));
  }

  recomputeListen(appPath: string) {
    this.recomputeListensSubject$$.next(this.socket.listen(`${appPath}-recompute`));
  }
}
