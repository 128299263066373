import { DataSpacePermissions } from './DataSpaceModel';
import { UserGroupPermission } from './userGroupPermission';

/**
 * An optimSpace is the set of all the inputs needed to compute a solution to the problem.
 * This data structure contains optimSpace metadata returned by the API.
 */
export class OptimSpaceModel {
  id: number;
  name: string;
  description: string;
  createdAt: Date;
  initialInputId?: number;
  dataSpace: DataSpace;
  authorId: number;
  numInputs: number;
  numOutputs: number;
  appVersion: ApplicationVersion;
  parameter?: Parameter;

  static getParameters(current: OptimSpaceModel, previous: OptimSpaceModel): Parameter {
    const isTheSameDataSpaceAndApp =
      current.dataSpace?.id === previous?.dataSpace?.id &&
      current.appVersion?.version === previous?.appVersion?.version;

    const parameterId = isTheSameDataSpaceAndApp
      ? previous?.parameter?.parameterId
      : current?.parameter?.parameterId;

    const favoriteParameterId = current?.parameter?.favoriteParameterId;

    return {
      parameterId: parameterId ?? favoriteParameterId,
      favoriteParameterId
    };
  }
}

export class OptimSpaceDetailModel extends OptimSpaceModel {
  inputs: Input[];
}

interface DataSpace {
  id: number;
  name: string;
  description: string;
  permissions?: DataSpacePermissions;
}

interface Input {
  id: number;
  createdAt: Date;
  outputs: Output[];
}

interface Output {
  id: number;
  createdAt: Date;
}

interface ApplicationVersion {
  version: string;
  appPath: string;
}

interface Parameter {
  favoriteParameterId: number;
  parameterId: number;
}
