import { Component, OnInit, Input } from '@angular/core';
import { DataSpacePermissions } from 'src/app/core/models/DataSpaceModel';

@Component({
  selector: 'app-optimspace-menu-component',
  templateUrl: './optimspace-menu-component.component.html'
})
export class OptimSpaceMenuComponentComponent implements OnInit {
  @Input() optimSpaceId: number;
  @Input() optimSpaceName: string;
  @Input() optimSpaceCreatedAt: Date;
  @Input() optimSpaceDescription: string;
  @Input() sideBarCollapsed: boolean = false;
  @Input() permissions: DataSpacePermissions = new DataSpacePermissions();

  constructor() {}
  ngOnInit(): void {
    if (this.permissions == null) {
      this.permissions = new DataSpacePermissions();
      Object.keys(this.permissions).forEach((key) => {
        this.permissions[key] = false;
      });
    }
  }
}
