import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ENDPOINT } from '../enum/endpoint.enum';
import { NewVisuData } from '../models/Visu';
import { Application, ApplicationVersion, UserApplications } from '../models/Application';
import { Observable } from 'rxjs';
import { BASE_API_URL } from '../utils/injectors';
import { RunResponse } from '../models/RunResponse.dto';
import { InputMapping, OutputMapping } from '../models/Mapping';
import { map } from 'rxjs/operators';
import { ApplicationInputSchemasDto } from '../models/applicationSchemaDto';
import { ApiTranslatorService } from './apitranslator.service';
import { InputSchema } from '../models/Schema';

/**
 * The ApplicationsService stores the current application selected by the user and
 * provides methods to retrieve metadata & parameters of applications.
 */
@Injectable({
  providedIn: 'root'
})
export class ApplicationsService {
  // Controllers
  private readonly urlApplications: string;
  private readonly urlApplication: string;
  private readonly urlJobs: string;

  // Sub-elements
  private readonly version = '/version/';

  constructor(
    private readonly http: HttpClient,
    private readonly apiTranslatorSvc: ApiTranslatorService,
    @Inject(BASE_API_URL) private readonly apiUrl: string
  ) {
    this.urlApplications = `${apiUrl}/${ENDPOINT.APPLICATIONS}/`;
    this.urlApplication = `${apiUrl}/${ENDPOINT.APPLICATION}/`;
    this.urlJobs = `${apiUrl}/${ENDPOINT.JOBS}/`;
  }

  /**
   * HTTP request to Galia API to get the list of the applications and their metadata.
   * @returns an observable that emits the list of applications that the user has access to.
   */
  public getApplications$(): Observable<Application[]> {
    return this.http
      .get<UserApplications>(this.urlApplications + '?versions=true')
      .pipe(map((response) => response.result));
  }

  /**
   * HTTP request to Galia API to get the metadata of a given applicationVersion
   * @param appPath a string that represents the name of the application
   * @returns an obersvable that emits the metadata of the applicationVersion
   */
  public getLatestApplicationVersion$(
    appPath: string,
    version: string
  ): Observable<ApplicationVersion> {
    return this.http.get<ApplicationVersion>(
      this.urlApplication + appPath + this.version + version
    );
  }

  /**
   * Post a job to the API to solve the input asynchronously
   * @param inputId
   * @param parameterId
   * @returns an Observable RunResponseDto with { jobId: number, inputId: number optimSpaceId: number }
   */
  postAsyncOptimize$(
    inputId: number,
    parameterId: number,
    version: string
  ): Observable<RunResponse> {
    return this.http
      .request('POST', this.urlJobs + 'run-input/' + inputId, {
        body: {
          save_data: true, // saveData is set as true by default, the user must be able to chose wheter to save data or not to (not yet implemented).
          parameter_id: parameterId,
          include_output_kpi: true,
          version: version
        }
      })
      .pipe(map((r) => this.apiTranslatorSvc.modelToClass(RunResponse, r)));
  }

  /**
   * HTTP post request that fires on server a method that generates an output based on visudata
   * @param outputId
   * @param visuData
   * @returns an observable that emits the output data id
   */
  postAsyncRecompute$(outputId: number, visuData: NewVisuData): Observable<RunResponse> {
    return this.http
      .request('POST', this.urlJobs + 'recompute/' + outputId, {
        body: {
          save_data: true, // saveData is set as true by default, the user must be able to chose wheter to save data or not to (not yet implemented).
          visu: visuData,
          include_input_kpi: true,
          include_output_kpi: true
        }
      })
      .pipe(map((r) => this.apiTranslatorSvc.modelToClass(RunResponse, r)));
  }

  /**
   * Fetch all inputSchemas for the application
   * @param appPath the application identifier
   * @param appVersion the application version
   * @returns
   */
  getApplicationSchemas(
    appPath: string,
    appVersion: string
  ): Observable<ApplicationInputSchemasDto> {
    return this.http.get<ApplicationInputSchemasDto>(
      `${this.apiUrl}/${ENDPOINT.SCHEMA}/input/${appPath}/${appVersion}`
    );
  }

  getApplicationInputMappings(
    appPath: string,
    version: string,
    businessUnit: number
  ): Observable<Array<InputMapping>> {
    return this.http.get<Array<InputMapping>>(
      `${this.apiUrl}/${ENDPOINT.MAPPING}/input/${appPath}/${version}/${businessUnit}`
    );
  }

  getApplicationInputSchema$(
    appPath: string,
    version: string
  ): Observable<{ initial_input: InputSchema }> {
    return this.http.get<{ initial_input: InputSchema }>(
      `${this.apiUrl}/${ENDPOINT.SCHEMA}/input/${appPath}/${version}`
    );
  }

  getApplicationOutputMappings(
    appPath: string,
    version: string,
    businessUnit: number
  ): Observable<Array<OutputMapping>> {
    return this.http.get<Array<OutputMapping>>(
      `${this.apiUrl}/${ENDPOINT.MAPPING}/output/${appPath}/${version}/${businessUnit}`
    );
  }
}
