import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { interval, of } from 'rxjs';
import { catchError, finalize, mapTo, switchMap } from 'rxjs/operators';
import { BASE_API_URL } from '../utils/injectors';
import { ENDPOINT } from '../enum/endpoint.enum';

@Injectable({
  providedIn: 'root'
})
export class HeartbeatService {
  private readonly urlHealth: string;
  private backendUp = true;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(BASE_API_URL) private readonly apiUrl: string
  ) {
    this.urlHealth = `${apiUrl}/${ENDPOINT.HEALTH}/`;
  }

  startHeartbeat() {
    interval(10000)
      .pipe(
        switchMap(() =>
          this.http.get(this.urlHealth).pipe(
            mapTo(true),
            catchError((error) => {
              this.backendUp = false;
              this.router.navigate(['/health']);
              return of(false);
            })
          )
        )
      )
      .subscribe((result) => {
        if (!this.backendUp && result) {
          this.backendUp = true;
          this.router.navigate(['/']);
        }
      });
  }
}
