import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ENDPOINT, EndPointV4 } from '../enum/endpoint.enum';
import { BASE_API_URL } from '../utils/injectors';
import { OutputModel } from '../models/OutputModel';
import { VisuAndKpis } from '../models/VisuAndKpis';
import { ApiTranslatorService } from './apitranslator.service';
import { PageDto } from '../models/Application';
import { Kpi } from '../models/Kpi';
import { UpdateOutput } from '../models/UpdateOutput';

/**
 * The OutputService provides methods to retrieve data of
 * a given output, OptimSpace and application
 */
@Injectable({
  providedIn: 'root'
})
export class OutputService {
  private readonly urlOutputs;
  private readonly urlOutputsV4;
  private readonly all = 'all';

  constructor(
    private readonly http: HttpClient,
    private readonly apiTranslatorService: ApiTranslatorService,
    @Inject(BASE_API_URL) private readonly apiUrl: string
  ) {
    this.urlOutputs = `${apiUrl}/${ENDPOINT.OUTPUTS}/`;
    this.urlOutputsV4 = `${apiUrl}/${EndPointV4.OUTPUTS}`;
  }

  /**
   * HTTP request that retrieves data of an output from server
   * @param outputId
   * @returns an observable that emits the output data
   */
  getOutput(outputId: number): Observable<OutputModel> {
    return this.http.request('GET', this.urlOutputs + outputId).pipe(
      map((r) => {
        const l = this.apiTranslatorService.modelToClass(OutputModel, r);
        return l;
      })
    );
  }

  /**
   * HTTP request that retrieves report of an output from server
   * @param outputId
   * @returns an observable that emits the output data
   */
  getReport(outputId: number): Observable<any> {
    console.log(this.urlOutputs + outputId + '/csv-report');

    return this.http
      .get(this.urlOutputs + outputId + '/csv-report', { responseType: 'text' })
      .pipe(
        map((r: string) => {
          const blob = new Blob([r], { type: 'text/csv' });
          const url = window.URL.createObjectURL(blob);
          const fileName = `csv-report-${outputId}.csv`;
          const link = document.createElement('a');
          link.href = url;
          link.download = fileName;
          link.click();
          window.URL.revokeObjectURL(url);
        })
      );
  }

  /**
   * HTTP request that the retrieves the visudata and kpis of an output.
   * We add an output id to know when the visu data changes.
   * @param outputId
   * @returns an observable that emits the visudata, input kpis and output kpis
   */
  getOutputVisuAndKpis(outputId: number): Observable<VisuAndKpis> {
    return this.http.get<VisuAndKpis>(this.urlOutputs + outputId + '/visu-and-kpi').pipe(
      map((visuAndKpis: VisuAndKpis) => {
        visuAndKpis.visuData._id = `output-${outputId}`;
        return visuAndKpis;
      })
    );
  }

  /**
   * HTTP request that the retrieves the visudata and kpis of an output.
   * We add an output id to know when the visu data changes.
   * @param outputId
   * @returns an observable that emits the visudata, input kpis and output kpis
   */
  getOutputKpi(outputId: number): Observable<Kpi> {
    return this.http.get<Kpi>(this.urlOutputs + outputId + '/kpi').pipe(
      map((kpi: Kpi) => {
        return kpi;
      })
    );
  }

  /**
   * HTTP request to delete an output from the database
   * @param outputId
   * @returns observable that emits the response of the API
   */
  deleteOutput(outputId: number): Observable<any> {
    return this.http.delete(this.urlOutputs + outputId);
  }

  /**
   * HTTP request to delete all outputs for a given input id from the database
   * @param inputId
   * @returns observable that emits the response of the API
   */
  getOutputsByInputId(
    inputId: number,
    cursor: number,
    orderBy: string,
    startDate: string,
    endDate: string
  ): Observable<{ outputs: OutputModel[]; cursor: number }> {
    return this.http
      .get<PageDto<OutputModel, number>>(
        this.urlOutputs +
          this.all +
          '/' +
          inputId +
          `?take=50&cursor=${cursor}&orderBy=${orderBy}&fromDate=${startDate}&toDate=${endDate}`
      )
      .pipe(
        map((result) => {
          return {
            outputs: result.result.map((r) =>
              this.apiTranslatorService.modelToClass(OutputModel, r)
            ),
            cursor: result.cursor_next_page
          };
        })
      );
  }

  updateOutput(dto: UpdateOutput): Observable<UpdateOutput> {
    return this.http.put<UpdateOutput>(`${this.urlOutputsV4}/${dto.id}`, {
      name: dto.name
    });
  }
}
