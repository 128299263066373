export enum ENDPOINT {
  AUTH = 'v3/auth/token',
  REFRESH_TOKEN = 'v3/auth/refresh-token',
  GROUP = 'v3/group',
  BUSINESS_UNIT = 'v3/businessunit',
  APPLICATIONS = 'v3/application/all',
  APPLICATION = 'v3/application',
  OPTIMSPACES = 'v3/optimspace',
  INPUTS = 'v3/input',
  JOBS = 'v3/jobs',
  PASSWORD = 'v3/reset-password',
  OUTPUTS = 'v3/output',
  PROFILE = 'v3/auth/profile',
  TOKENVISU = 'v3/token-visualization',
  VISUALIZATION = 'v3/visualization',
  MAPPING = 'v3/mapping',
  PARAMETERS = 'v3/parameter',
  USER = 'v3/user',
  HEALTH = 'v3/health',

  SCHEMA = 'v3/schema', // Sub-path of 'applications'
  GEOCODE = 'geocoding' // not exposed to regular user
}

export enum EndPointV4 {
  OPTIMSPACES = 'v4-beta/optimspaces',
  DATASPACES = 'v4-beta/dataspaces',
  PARAMETERS = 'v4-beta/parameters',
  OUTPUTS = 'v4-beta/outputs'
}
