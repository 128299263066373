/**
 * An application is the julia module that optimize the model of the user problem.
 * This data strucure contains application metadata returned by the API.
 */

import { ParametersModel } from './ParametersModel';

export class Application {
  id: number;
  name: string;
  description: string;
  async: boolean;
  dynamic: boolean;
  path: string;
  created_at: Date;
  updated_at: Date;
  versions?: string[];
}

export class ApplicationVersion {
  id: number;
  version: string;
  release_note: string;
  parameters: ParametersModel[];
  application: Application = new Application();
  favorite_parameter_id: number;
}

export class UserApplications {
  cursor_next_page: number;
  cursor_prev_page: number;
  result: Application[];
}

export class PageDto<I, U> {
  readonly cursor_next_page: U;
  readonly cursor_prev_page: U;
  readonly result: I[];
}
