import { OutputModel } from './OutputModel';

/**
 * The Dto received as response of asynchronous requests
 * (received through the web-socket interface)
 */
export class WebSocketResponseDto {
  public appPath: number;
  public appVersion: string; //This field is not given in the web socket response. We populate it in the frontend. In the V4 of the API this info will come from GBE
  public inputId: number;
  public jobId: number;
  public output: OutputModel;

  /**
   * User's metadata
   */
  public metadata: string;
}
