/**
 * An output is the solution returned by an application for an update of an optimSpace optimized
 * with a given set of parameters.
 * This data structure contains output data returned by the API.
 */
export class OutputModel {
  id: number;
  name: string | null;
  appPath: string;
  optimSpaceId: number;
  inputId: number;
  createdAt: Date;

  /**
   * Application-specific data
   */
  data: any;

  parameterId: number;
  errors?: string[];
  warnings?: string[];
}
