import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import {
  initialDataPickerState,
  OutputPickerState,
  OutputPickerTableState
} from './output-picker.state';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mapTo, merge, of, switchMap, throwError, toArray } from 'rxjs';
import {
  deleteOutput,
  deleteOutputs,
  deleteRow,
  loadOutputs,
  selectOutput,
  triggerAlert,
  updateOutputData,
  updateSpinner
} from './output-picker.actions';
import { outputSelection } from 'src/app/core/store/application/application.actions';
import { OutputService } from 'src/app/core/services/output.service';

@Injectable()
export class OutputPickerEffect extends ComponentStore<OutputPickerState> {
  constructor(
    private readonly outputSvc: OutputService,
    private readonly actions$: Actions
  ) {
    super(initialDataPickerState);
  }

  fireSelection$ = createEffect(() =>
    this.actions$.pipe(
      ofType(selectOutput),
      switchMap(({ id, dest }) =>
        of(updateSpinner({ status: true }), outputSelection({ outputId: id, dest }))
      )
    )
  );

  fireDeletion$ = createEffect(() =>
    this.actions$.pipe(
      ofType(deleteOutput, deleteOutputs),
      switchMap(({ id }) => {
        const ids = Array.isArray(id) ? id : [id];
        return merge(
          ...ids.map((id) =>
            this.outputSvc.deleteOutput(id).pipe(
              mapTo(id),
              catchError((error) => throwError(error))
            )
          )
        ).pipe(
          toArray(),
          switchMap((deletedIds) => {
            const successMsg = `Removed instance(s) #${deletedIds.join(', ')}.`;
            return of(
              deleteRow({ id }),
              triggerAlert({
                message: {
                  category: 'success',
                  message: successMsg,
                  persistentMessage: false,
                  details: null
                }
              })
            );
          }),
          catchError((err) =>
            of(
              triggerAlert({
                message: {
                  category: 'error',
                  message: `Error deleting instance(s): ${err.message}`,
                  persistentMessage: false,
                  details: null
                }
              })
            )
          )
        );
      })
    )
  );

  fetchOutput$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(loadOutputs),
      switchMap(({ inputId, cursor, orderBy, startDate, endDate }) => {
        return this.outputSvc
          .getOutputsByInputId(inputId, cursor, orderBy, startDate, endDate)
          .pipe(
            map((result) => {
              const colNames = ['#ID', 'Name', 'Created'];
              const rows = [];
              result.outputs.map(({ id, name, createdAt }) => {
                rows.push({
                  id,
                  name,
                  createdAt
                });
              });
              const cursor = result.cursor;
              const data: OutputPickerTableState = {
                colNames: colNames,
                rows: rows,
                orderBy,
                startDate,
                endDate
              };
              return updateOutputData({ data, cursor });
            }),
            catchError(() =>
              of(
                triggerAlert({
                  message: {
                    category: 'error',
                    message: 'Cannot load instances.',
                    persistentMessage: false,
                    details: null
                  }
                })
              )
            )
          );
      })
    );
  });
}
