import { BusinessUnit } from 'src/app/core/models/BusinessUnit';
import { UserGroup } from './UserGroup';

/**
 * A user is someone that has access to the galia API.
 * This data structure contains user data returned by the API.
 */
export class User {
  id?: number;
  user_name: string;
  full_name?: string;
  email: string;
  can_manage_applications?: boolean;
  can_manage_business_units?: boolean;
  business_unit?: BusinessUnit;
  user_group?: UserGroup;
  active?: boolean;
}

export class UserForList {
  id?: number;
  user_name: string;
  full_name?: string;
  email: string;
  can_manage_applications?: boolean;
  can_manage_business_units?: boolean;
  business_unit?: BusinessUnit;
  user_group_id?: number;
  user_group_name?: string;
  active?: boolean;
}
