import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { BASE_API_URL } from '../utils/injectors';
import { UserGroupPermission } from '../models/userGroupPermission';
import { ENDPOINT, EndPointV4 } from '../enum/endpoint.enum';
import { PageDto } from '../models/Application';
import { UserForList } from '../models/User';
import { DataSpaceModel, DataSpacePermissions } from '../models/DataSpaceModel';
import { ApiTranslatorService } from './apitranslator.service';

@Injectable({
  providedIn: 'root'
})
export class DataSpaceService {
  private readonly urlPermission: string;
  private readonly urlUsers: string;
  private readonly urlDataSpaces: string;

  constructor(
    private readonly http: HttpClient,
    @Inject(BASE_API_URL) private readonly apiUrl: string,
    private readonly apiTranslatorService: ApiTranslatorService
  ) {
    this.urlPermission = `${this.apiUrl}/${ENDPOINT.GROUP}/permission/`;
    this.urlUsers = `${apiUrl}/${ENDPOINT.USER}/all/`;
    this.urlDataSpaces = `${apiUrl}/${EndPointV4.DATASPACES}/`;
  }

  public getAllPermissions$(
    groupId: number
  ): Observable<{ permissions: DataSpacePermissions[]; cursor: number }> {
    return this.http
      .get<PageDto<DataSpacePermissions, number>>(this.urlPermission + groupId + '/all')
      .pipe(
        map((result) => {
          return {
            permissions: this.apiTranslatorService.modelToCamelCase(result.result),
            cursor: result.cursor_next_page
          };
        })
      );
  }

  public getPermissionsById(
    dataSpaceId: number,
    applicationPath: string
  ): Observable<DataSpacePermissions> {
    return this.http
      .get<DataSpacePermissions>(
        `${this.urlPermission}${dataSpaceId}/on/${applicationPath}`
      )
      .pipe(map((result) => this.apiTranslatorService.modelToCamelCase(result)));
  }

  //Retrieve all users of the user's userGroup
  public getUsersOfUserGroup$(): Observable<{ users: UserForList[]; cursor: number }> {
    return this.http.get<PageDto<UserForList, number>>(this.urlUsers).pipe(
      map((result) => {
        return { users: result.result, cursor: result.cursor_next_page };
      })
    );
  }

  public getDataSpaces$(
    search: string,
    cursor?: number
  ): Observable<{ dataSpaces: DataSpaceModel[]; cursor: number }> {
    const params = {
      take: 25,
      ...(search && { search }),
      ...(cursor && { cursor })
    };
    const httpParams = new HttpParams({ fromObject: params });
    return this.http
      .get<PageDto<DataSpaceModel, number>>(this.urlDataSpaces, { params: httpParams })
      .pipe(
        map((result) => {
          return {
            dataSpaces: result.result.map((r) =>
              this.apiTranslatorService.modelToClass(DataSpaceModel, r)
            ),
            cursor: result.cursor_next_page
          };
        })
      );
  }
}
