import { Component, Output, EventEmitter, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  selectActiveInputKpisBtn,
  selectActiveListBtn,
  selectActiveOutputKpisBtn,
  selectActiveOutputBtn,
  selectActiveTimelineBtn,
  selectCurApp,
  selectCurControlsVisuOnScreen,
  selectCurOptimSpace,
  selectCurOutput,
  selectCurOutputVisu,
  selectShowInputKpis,
  selectShowList,
  selectShowOutputKpis,
  selectShowTimeline,
  selectActivePackage3DBtn,
  selectShowPackage,
  selectShowMap,
  selectActiveMap,
  selectShowCaption,
  selectActiveCaptionBtn,
  selectShowWarehouse,
  selectActiveWarehouseBtn
} from 'src/app/core/store/application/application.selectors';
import { RootState } from 'src/app/core/store';
import {
  inputFilterResetPrimaryAggregator,
  outputFilterResetPrimaryAggregator,
  showInputVisu,
  showOutputVisu,
  toggleCaption,
  toggleInputKpis,
  toggleList,
  toggleMap,
  toggleOutputKpis,
  togglePackage,
  toggleTimeline,
  toggleWarehouse
} from 'src/app/core/store/application/application.actions';
import { selectCurUser } from 'src/app/core/store/auth/auth.selectors';
import { selectMapProvider } from 'src/app/feature/applications/map-provider-picker/map-provider-selector';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html'
})
export class SidebarComponent {
  application$ = this.store.select(selectCurApp);
  user$ = this.store.select(selectCurUser);

  optimSpace$ = this.store.select(selectCurOptimSpace);
  output$ = this.store.select(selectCurOutput);

  outputVisu$ = this.store.select(selectCurOutputVisu);
  controlVisuOnScreen$ = this.store.select(selectCurControlsVisuOnScreen);

  activeShowInput$ = this.store.select(selectActiveInputKpisBtn);
  activeShowOutput$ = this.store.select(selectActiveOutputKpisBtn);
  activeShowOutputBtn$ = this.store.select(selectActiveOutputBtn);
  activeShowTimeline$ = this.store.select(selectActiveTimelineBtn);
  activeShowList$ = this.store.select(selectActiveListBtn);
  activeShowPackage3D$ = this.store.select(selectActivePackage3DBtn);
  activeShowWarehouse$ = this.store.select(selectActiveWarehouseBtn);
  activeShowMap$ = this.store.select(selectActiveMap);
  activeShowCaption$ = this.store.select(selectActiveCaptionBtn);

  showInputKpis$ = this.store.select(selectShowInputKpis);
  showOutputKpis$ = this.store.select(selectShowOutputKpis);
  showTimeline$ = this.store.select(selectShowTimeline);
  showList$ = this.store.select(selectShowList);
  showPackage$ = this.store.select(selectShowPackage);
  showWarehouse$ = this.store.select(selectShowWarehouse);
  showMap$ = this.store.select(selectShowMap);
  showCaption$ = this.store.select(selectShowCaption);

  mapProvider$ = this.store.select(selectMapProvider);

  @Output() public option = new EventEmitter();

  @Output() exportOutputEmitter = new EventEmitter();
  @Output() exportCSVOutputEmitter = new EventEmitter();
  @Output() exportJSONOutputEmitter = new EventEmitter();
  @Output() isSideBarCollapsedEmitter = new EventEmitter();

  @Input() sideBarCollapsed: boolean = false;
  @Input() isVisualizationMode: boolean = false;

  public title = 'Galia';

  constructor(private readonly store: Store<RootState>) {}

  onSidebarToggle() {
    this.isSideBarCollapsedEmitter.emit(!this.sideBarCollapsed);
  }

  onClickInputKpis() {
    this.store.dispatch(toggleInputKpis());
  }

  onClickOutputKpis() {
    this.store.dispatch(toggleOutputKpis());
  }

  onClickTimeline() {
    this.store.dispatch(toggleTimeline());
  }

  onClickList() {
    this.store.dispatch(toggleList());
  }

  onClickWarehouse() {
    this.store.dispatch(toggleWarehouse());
  }

  onClickPackage() {
    this.store.dispatch(inputFilterResetPrimaryAggregator());
    this.store.dispatch(outputFilterResetPrimaryAggregator());
    this.store.dispatch(togglePackage());
  }

  onClickMap() {
    this.store.dispatch(inputFilterResetPrimaryAggregator());
    this.store.dispatch(outputFilterResetPrimaryAggregator());
    this.store.dispatch(toggleMap());
  }
  onClickCaption() {
    this.store.dispatch(toggleCaption());
  }

  onToggle(event, showTimeline: boolean) {
    if (event) {
      this.store.dispatch(showOutputVisu());
    } else {
      this.store.dispatch(showInputVisu());
      if (showTimeline) {
        this.store.dispatch(toggleTimeline());
      }
    }
  }

  resetMainView(controls: SidebarVisuControls) {
    if (controls.showMap) {
      this.store.dispatch(toggleMap());
    }
    if (controls.showPackage) {
      this.store.dispatch(togglePackage());
    }
    if (controls.showList) {
      this.store.dispatch(toggleList());
    }
    if (controls.showTimeline) {
      this.store.dispatch(toggleTimeline());
    }
  }
}

interface SidebarVisuControls {
  showTimeline: boolean;
  showList: boolean;
  showPackage: boolean;
  showMap: boolean;
  showWarehouse: boolean;
}
