<a
  data-cy="instance_table"
  class="block sidelink"
  routerLink="optimspace-picker"
  routerLinkActive="active-sidelink"
  queryParamsHandling="merge"
>
  <div
    *ngIf="!sideBarCollapsed; else sidebarIsCollapsed"
    class="inline-flex items-center align-middle"
  >
    <phosphor-icon
      class="w-4 stroke-neutral-900 fill-neutral-500 mr-4"
      name="list"
    ></phosphor-icon>
    <span> Instance table </span>
  </div>
  <ng-template #sidebarIsCollapsed>
    <div class="inline-flex items-center align-middle">
      <phosphor-icon
        class="w-4 stroke-neutral-900 fill-neutral-500"
        name="list"
      ></phosphor-icon>
    </div>
  </ng-template>
</a>

<a
  data-cy="new_instance"
  class="block sidelink"
  routerLink="upload/new-instance"
  routerLinkActive="active-sidelink"
  queryParamsHandling="merge"
>
  <div
    *ngIf="!sideBarCollapsed; else sidebarIsCollapsed2"
    class="inline-flex items-center align-middle"
  >
    <phosphor-icon
      class="w-4 stroke-neutral-900 fill-neutral-500 mr-4"
      name="plus"
    ></phosphor-icon>
    <span> New instance </span>
  </div>
  <ng-template #sidebarIsCollapsed2>
    <div class="inline-flex items-center align-middle">
      <phosphor-icon
        class="w-4 stroke-neutral-900 fill-neutral-500"
        name="plus"
      ></phosphor-icon>
    </div>
  </ng-template>
</a>

<ng-template
  [ngIf]="optimSpaceId && permissions.canOptimize"
  [ngIfElse]="editOptimSpaceUnactive"
>
  <a
    data-cy="edit_instance"
    class="block sidelink"
    routerLink="instance-editor"
    routerLinkActive="active-sidelink"
    queryParamsHandling="merge"
  >
    <div
      *ngIf="!sideBarCollapsed; else sidebarIsCollapsed3"
      class="inline-flex items-center align-middle"
    >
      <phosphor-icon
        class="w-4 stroke-neutral-900 fill-neutral-500 mr-4"
        name="pen"
      ></phosphor-icon>
      <span> Edit instance </span>
    </div>
    <ng-template #sidebarIsCollapsed3>
      <div class="inline-flex items-center align-middle">
        <phosphor-icon
          class="w-4 stroke-neutral-900 fill-neutral-500"
          name="pen"
        ></phosphor-icon>
      </div>
    </ng-template>
  </a>
</ng-template>
<ng-template #editOptimSpaceUnactive>
  <a data-cy="edit_instance" class="block unactive-sidelink">
    <div
      *ngIf="!sideBarCollapsed; else sidebarIsCollapsed4"
      class="inline-flex items-center align-middle"
    >
      <phosphor-icon
        class="w-4 stroke-neutral-400 fill-neutral-100 mr-4"
        name="pen"
      ></phosphor-icon>
      <span> Edit instance </span>
    </div>
    <ng-template #sidebarIsCollapsed4>
      <div class="inline-flex items-center align-middle">
        <phosphor-icon
          class="stroke-neutral-400 fill-neutral-100 w-4"
          name="pen"
        ></phosphor-icon>
      </div>
    </ng-template>
  </a>
</ng-template>
