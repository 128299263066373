import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ENDPOINT, EndPointV4 } from '../enum/endpoint.enum';
import { BASE_API_URL } from '../utils/injectors';
import { PageDto } from '../models/Application';
import { OptimSpaceDetailModel, OptimSpaceModel } from '../models/OptimSpaceModel';
import { UploadOptimSpaceModel } from '../models/UploadOptimSpaceModel';
import { ApiTranslatorService } from './apitranslator.service';
import { Kpi } from '../models/Kpi';
import { UpdateOptimSpace } from '../models/UpdateOptimSpace';

@Injectable({
  providedIn: 'root'
})
export class OptimSpacesService {
  private readonly urlOptimSpaceV4: string;
  private readonly urlOptimSpaceV3: string;
  private readonly urlInput: string;
  private readonly newInput = 'new';
  private readonly newCsvInput = 'csv/new';

  constructor(
    private readonly http: HttpClient,
    private readonly apiTranslatorService: ApiTranslatorService,
    @Inject(BASE_API_URL) private readonly apiUrl: string
  ) {
    this.urlOptimSpaceV4 = `${apiUrl}/${EndPointV4.OPTIMSPACES}/`;
    this.urlOptimSpaceV3 = `${apiUrl}/${ENDPOINT.OPTIMSPACES}/`;
    this.urlInput = `${apiUrl}/${ENDPOINT.INPUTS}/`;
  }

  /**
   * * HTTP request to Galia API to get a single OptimSpaces
   * @param OptimSpaceId a number that represents the ID of the OptimSpace to be retrieved
   * @returns  an observable that emits the selected optimSpace
   */
  getOptimSpace$(optimSpaceId: number): Observable<OptimSpaceDetailModel> {
    return this.http
      .get<OptimSpaceModel>(this.urlOptimSpaceV4 + optimSpaceId)
      .pipe(map((r) => this.apiTranslatorService.modelToClass(OptimSpaceDetailModel, r)));
  }

  /**
   * HTTP request to Galia API to get the list of the optimSpace of the application referenced by `appPath`
   * @param appPath a string that represents the name of the application
   * @returns an observable that emits the list of optimSpace of the application
   */
  getApplicationOptimSpace$(
    appPath: string,
    version: string,
    cursor: number,
    descendingOrder: string,
    startDate: string,
    endDate: string,
    searchQuery: string,
    dataSpaceIds: number[]
  ): Observable<{ optimSpaces: OptimSpaceModel[]; cursor: number }> {
    const params = {
      take: 50,
      ...(cursor && { cursor }),
      ...(descendingOrder && { order_by: descendingOrder }),
      ...(searchQuery && { search: searchQuery }),
      ...(startDate && { from_date: startDate }),
      ...(endDate && { to_date: endDate }),
      ...(dataSpaceIds?.length > 0 && { data_space_ids: dataSpaceIds }),
      version
    };

    const httpParams = new HttpParams({ fromObject: params });
    const url = `${this.urlOptimSpaceV4}${appPath}/all`;
    return this.http
      .get<PageDto<OptimSpaceModel, number>>(url, {
        params: httpParams
      })
      .pipe(
        map((result) => {
          return {
            optimSpaces: result.result.map((r) =>
              this.apiTranslatorService.modelToClass(OptimSpaceModel, r)
            ),
            cursor: result.cursor_next_page
          };
        })
      );
  }

  /**
   * HTTP request to save a new optimSpace along with the new input for the application referenced by `appPath`
   * @param appPath a string that represents the application path
   * @param newOptimSpace an UploadOptimSpaceModel object that represents the new optimSpace to be saved that contains the input to be created
   * @returns an observable that emits optimSpaceId and the initialInputId of the new optimSpace
   */
  postOptimSpace$(newOptimSpace: UploadOptimSpaceModel): Observable<any> {
    const data = {
      ...newOptimSpace,
      group_id: newOptimSpace.dataSpaceId
    };
    return this.http.post(`${this.urlInput + this.newInput}`, data);
  }

  objToCSVString(data: Object) {
    const headers = Object.keys(data);
    const vectors = Object.values(data);
    const csvRows = [];
    // This line will cause problems according to how the client formats their float numbers
    const delimiter = ',';

    // Construct header row
    const headerRow = headers.join(delimiter);
    csvRows.push(headerRow);

    // Fill the rest of the rows
    const maxLength = Math.max(...vectors.map((vector) => vector.length));
    for (let rowIdx = 0; rowIdx < maxLength; rowIdx++) {
      let row = [];
      for (let colIdx = 0; colIdx < vectors.length; colIdx++) {
        if (vectors[colIdx].length < rowIdx || vectors[colIdx][rowIdx] == null) {
          row.push(''); // null, missing, "", undefined
        } else if (
          typeof vectors[colIdx][rowIdx] == 'string' &&
          vectors[colIdx][rowIdx].includes(',')
        ) {
          row.push(`"${vectors[colIdx][rowIdx]}"`); // wrap strings containing comma in double quotes
        } else {
          row.push(vectors[colIdx][rowIdx]); // 0, 0.0, 1, -1, "non empty string"
        }
      }
      row.join(delimiter);
      csvRows.push(row);
    }
    return csvRows.join('\n');
  }

  /**
   * HTTP request to save a new optimSpace along with the new input for the application referenced by `appPath`
   * @param appPath a string that represents the application path
   * @param newOptimSpace an UploadOptimSpaceModel object that represents the new optimSpace to be saved that contains the input to be created
   * @returns an observable that emits optimSpaceId and the initialInputId of the new optimSpace
   */
  postOptimSpaceCsv$(newOptimSpace: UploadOptimSpaceModel): Observable<any> {
    const { name, description, app_path, version } = newOptimSpace;
    const options = {
      params: { name, description, app_path, group_id: newOptimSpace.dataSpaceId, version }
    };
    const csv_str = this.objToCSVString(newOptimSpace.data);
    return this.http.post(`${this.urlInput + this.newCsvInput}`, csv_str, options);
  }

  /**
   * HTTP request to delete an optimSpace
   * @param optimSpaceId a number that represents the  optimSpace ID
   * @returns
   */
  deleteOptimSpace$(optimSpaceId: number): Observable<any> {
    return this.http.delete(this.urlOptimSpaceV3 + optimSpaceId);
  }

  /**
   * HTTP request that the retrieves kpis of an input.
   * We add an output id to know when the visu data changes.
   * @param inputId
   * @returns an observable kpis
   */
  getOptimSpaceKpi(inputId: number): Observable<Kpi> {
    return this.http.get<Kpi>(this.urlInput + inputId + '/kpi').pipe(
      map((kpi: Kpi) => {
        return kpi;
      })
    );
  }

  updateOptimSpace(dto: UpdateOptimSpace): Observable<UpdateOptimSpace> {
    return this.http.put<UpdateOptimSpace>(`${this.urlOptimSpaceV4}${dto.id}`, {
      name: dto.name,
      description: dto.description?.length > 0 ? dto.description : null
    });
  }
}
