export class DataSpaceModel {
  id: number;
  name: string;
  description: string;
}

export class DataSpacePermissions {
  userGroupId: number;
  appPath: string;
  versionId: number;
  canOptimize: boolean;
  canRecompute: boolean;
  canUploadInput: boolean;
  canUploadOutput: boolean;
  canExportOutput: boolean;
  canDeleteInput: boolean;
  canUploadParameters: boolean;
  canEditParameters: boolean;
  canDeleteOutput: boolean;
  canSeeJobLogs: boolean;
}
