import { HttpClient, HttpParams } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable, catchError, map, of } from 'rxjs';
import { ENDPOINT, EndPointV4 } from '../enum/endpoint.enum';
import { BASE_API_URL } from '../utils/injectors';
import { ParameterDetailModel, ParameterItemModel } from '../models/ParametersModel';
import { ApiTranslatorService } from './apitranslator.service';
import { PageDto } from '../models/Application';

@Injectable({
  providedIn: 'root'
})
export class ParamsService {
  private readonly urlParameters: string;
  private readonly urlParametersV4: string;

  constructor(
    private readonly http: HttpClient,
    private readonly apiTranslatorService: ApiTranslatorService,
    @Inject(BASE_API_URL) private readonly apiUrl: string
  ) {
    this.urlParameters = `${apiUrl}/${ENDPOINT.PARAMETERS}`;
    this.urlParametersV4 = `${apiUrl}/${EndPointV4.PARAMETERS}`;
  }

  /**
   * HTTP request to delete an optimSpace of parameters
   * @param parameterId id of the set of parameters we want to delete.
   * @returns observable that emits the reponse of the API
   */
  public deleteParameter$(parameterId: number): Observable<any> {
    return this.http.delete(this.urlParameters + '/' + parameterId);
  }

  /**
   * Updates the favorite parameter with the specified app path and parameter ID.
   *
   * @param {string} appPath - The app path.
   * @param {number} parameterId - The parameter ID.
   * @return {Observable<any>} - An observable that emits the updated favorite parameter.
   */
  public updateFavoriteParameter$(
    appPath: string,
    parameterId: number,
    dataSpaceId: number
  ): Observable<any> {
    return this.http.put(this.urlParameters + '/favorite', {
      app_path: appPath,
      parameter_id: parameterId,
      group_id: dataSpaceId
    });
  }

  public getFavoriteParameterId$(
    appPath: string,
    appVersion: string,
    dataSpaceId: number
  ): Observable<number | null> {
    const httpParams = new HttpParams({ fromObject: { group_id: dataSpaceId } });
    return this.http
      .get(this.urlParameters + `/favorite/${appPath}/${appVersion}`, {
        params: httpParams
      })
      .pipe(
        map((response: { parameter_id: number }) => response.parameter_id),
        catchError(() => of(null))
      );
  }

  public getParameterById$(parameterId: number): Observable<ParameterDetailModel> {
    return this.http
      .get(`${this.urlParameters}/${parameterId}`)
      .pipe(map((response) => this.apiTranslatorService.modelToCamelCase(response)));
  }

  /**
   * HTTP request to save a new optimSpace of parameters for the current application
   * @param name name of the new optimSpace of parameters
   * @param description description of the new optimSpace of parameters
   * @param parameters object that contains the list of parameters and theirs values.
   * @returns observable that emits the reponse of the API
   */
  public postApplicationParameter$(
    appPath: string,
    version: string,
    name: string,
    description: string,
    parameters: Object,
    dataSpaceId: number
  ): Observable<any> {
    const body = {
      name: name,
      description: description,
      data: parameters,
      app_path: appPath,
      version: version,
      group_id: dataSpaceId
    };
    return this.http.post(this.urlParameters, body);
  }

  public getForOptimSpace$(
    appPath: string,
    appVersion: string,
    optimSpaceId: number,
    cursor?: number
  ): Observable<{ parameters: ParameterItemModel[]; cursor: number }> {
    const url = `${this.urlParametersV4}/${appPath}/${appVersion}`;
    const httpParams = new HttpParams({
      fromObject: {
        optim_space_id: optimSpaceId,
        take: 50,
        ...(cursor && { cursor })
      }
    });
    return this.http
      .get<PageDto<ParameterItemModel, number>>(url, { params: httpParams })
      .pipe(
        map((response) => ({
          parameters: this.apiTranslatorService.modelToCamelCase(response.result),
          cursor: response.cursor_next_page
        }))
      );
  }

  public updateParameter$(parameter: {
    id: number;
    name: string;
    description: string;
  }): Observable<void> {
    const url = `${this.urlParameters}/${parameter.id}`;
    return this.http
      .put(url, {
        name: parameter.name,
        description: parameter.description
      })
      .pipe(map(() => undefined));
  }
}
