/**
 * An update is a modification applied to a state in which the optimSpace finds itself.
 * This data structure contains update metadata returned by the API.
 */
export class InputModel {
  id: number;
  appPath: string;
  createdAt: Date;
  data: any;
  optimSpaceId: number;
  warnings: any[];
}
